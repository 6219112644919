import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import background from "../Figures/geweestengoedgekeurd2.jpg"
import pageLayout from "../pages/pages.module.css"

import maisoncretes from "../Figures/geweestengoedgekeurd/Vexin.jpg"
import caravan from "../Figures/geweestengoedgekeurd/Caravan.png"
import veere from "../Figures/geweestengoedgekeurd/BoerderijVeere.jpg"
import boerderijergens from "../Figures/geweestengoedgekeurd/BoerderijErgens.jpg"
import robertabyroby from "../Figures/geweestengoedgekeurd/Robertabyroby.jpg"

export default function geweestengoedgekeurd() {
  return (
    <div>
      <div>
      
      <Layout>
    </Layout>
      <div  style={{backgroundImage: "url(" + background + ")" ,backgroundSize: "cover", minHeight: "150px",zIndex:"1"}}>
        <div className={pageLayout.text}>
      <h1 style={{color: "black"}}>Locaties</h1>
      <br/>
      <h2 style={{color: "black"}}>bezocht en goedgekeurd door vrienden, dus zeker de moeite waard!</h2>
      </div>
      </div>
      </div>
      <div>
      <div className={pageLayout.text}>
        <h3>België:</h3>

        <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src={caravan} style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.ardennes-etape.be%2Fvakantiehuizen-ardennen%2FHuy%2FBuitengewoon-106337-01%2FWeekend-0_2-20200828.html%3Ffbclid%3DIwAR2aeZyQPy1ATTPn_ywZBzJMjKlRoSK783hiSekb0Q4krIZED7lBJ230xqY%23infoGen&h=AT18eiBXeD-dfjaXEG19drcTyjOYlCg3TMP9e03DELLvQLB39VZYiUZllBn-SNhQ_njYZOR-NKeB5IARDE9Sqd6OmcfUQX2lHj12adJSNpcqqfWQBrKsOn0ToCPY50Yk2TZ-Q37SusmKhzPF4g">Caravan in Huy</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">Deze woonwagen ligt op zo'n 100 meter afstand van de eigenaren van de boerderij en is voorzien van alle comfort. Je bevindt je dus in een landbouwomgeving voor het genot van kleine en grote dingen: schapen, een koeienweide, korenvelden, zonnebloemen en boomgaarden rondom. De eigenaren zullen jou hartelijk verwelkomen en je graag kennis laten maken met leven op de boerderij.</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/place/Hoei/@50.4850364,4.8350555,9.5z/data=!4m5!3m4!1s0x47c1a914dcef9941:0x40099ab2f4d69a0!8m2!3d50.5187153!4d5.2396656"> Huy</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>EPm & EPv</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2019</td>
      </tr>
    </table>
    <hr />
    <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src="https://static.wixstatic.com/media/22d4ec_8b9dae2d6c9143daa81b7df9fad4bb55~mv2_d_2694_1907_s_2.jpg/v1/fill/w_1093,h_773,al_c,q_90,usm_0.66_1.00_0.01/22d4ec_8b9dae2d6c9143daa81b7df9fad4bb55~mv2_d_2694_1907_s_2.webp" style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.ardennes-etape.be%2Fvakantiehuizen-ardennen%2FHuy%2FBuitengewoon-106337-01%2FWeekend-0_2-20200828.html%3Ffbclid%3DIwAR2aeZyQPy1ATTPn_ywZBzJMjKlRoSK783hiSekb0Q4krIZED7lBJ230xqY%23infoGen&h=AT18eiBXeD-dfjaXEG19drcTyjOYlCg3TMP9e03DELLvQLB39VZYiUZllBn-SNhQ_njYZOR-NKeB5IARDE9Sqd6OmcfUQX2lHj12adJSNpcqqfWQBrKsOn0ToCPY50Yk2TZ-Q37SusmKhzPF4g">Hof ter wilgen</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">Hof ter Wilgen is een vakantieverblijf met minicamping, gelegen langs een smal straatje, tussen velden en akkers, ver weg van stad en drukte.
Eens voorbij de parking loop je zomaar binnen in de permacultuurtuin, een klein paradijsje waar je nog stilte en rust kan vinden. Plaats voor een aantal tenten, rustige omgeving en het plezier van een zwemvijver!</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/place/Maroiestraat+10,+9500+Geraardsbergen/@50.7269165,3.9025854,12.21z/data=!4m5!3m4!1s0x47c3aefe981aaa9d:0x9a7e99cac1b33e6d!8m2!3d50.7338058!4d3.9383471"> Viane</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>K & K</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2020</td>
      </tr>
    </table>

        <h3>Frankrijk: </h3>

        <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src={maisoncretes} style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://www.https://maisondescretes.fr/">Maison des crêtes</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">In natuurgebied Vexin net boven Parijs. Een air B&B op top locatie met zicht op de Seine. Een buiten zwembad en super lekker ontbijt. La Roche-Guyon is een gezellig dorpje en in de buurt is het mooi wandelen en gezellig eten.</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/place/4+Route+des+Cr%C3%AAtes,+95780+La+Roche-Guyon,+Frankrijk/@49.079811,1.61833,10z/data=!4m5!3m4!1s0x47e6c657880d898b:0xbd1d5843c7bda214!8m2!3d49.0798109!4d1.6183303?hl=nl-NL"> La Roche-Guyon</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>Kaat & Korneel</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2020</td>
      </tr>
    </table>
    <hr />
    <h3>Italië:</h3>

    <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src={robertabyroby} style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://www.booking.com/hotel/it/roberta-by-roby.nl.html">Roberta by Roby</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">Roberta by Roby is een B&B met zwembad in Piëmonte (Italië) dat gerund wordt door een superlief ouder koppel die er alles aan doen om het je zo gezellig mogelijk te maken. Bovendien kan je 's avonds genieten van een 7 gangen menu inclusief wijn en water voor maar €25/pers, bestaande uit superlekkere traditionele Piëmontese gerechten. Absolute aanrader!</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/place/bed+and+breakfast+roberta+by+roby/@44.8151005,7.8012933,10.33z/data=!4m8!3m7!1s0x4787fb2e3a3e1f2b:0x7b2e7ba0f14c923a!5m2!4m1!1i2!8m2!3d44.8667519!4d7.9443298">Cellarengo</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>Stijn & Astrid</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2020</td>
      </tr>
    </table>
    <hr />
    <h3>Nederland:</h3>

    <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src={veere} style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://www.airbnb.be/rooms/10692549?s=67&shared_item_type=1&virality_entry_point=1&fbclid=IwAR2ApgTZIPc_MHu3oXp0kAZFwZ8heuWvFWF_3BeePod4WXocwpeCk2kfPks&source_impression_id=p3_1596293259_ok%2Fra%2F6yEQDj%2F3rt">Huÿze Veere</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">Een gezellig boerderijappartement met twee dubbele bedden en een klein keukentje vlakbij het schone Nederlandse Veere. Goedgekeurd tijdens een fietsweekend om eens helemaal tot rust te komen.</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/@51.53587,3.64598,14z?hl=nl">Veere</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>Kaat & Korneel</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2019</td>
      </tr>
    </table>
    <hr />
    <table cellPadding="0" border="0" >
      <tr>
          <td colspan="2" style={{marginBottom:0}}> 
              <figure style={{marginBottom:0}}>
        <img width="100%"  src={boerderijergens} style={{marginBottom:0}} alt="" />
        <figcaption style={{textAlign: "center",marginBottom:0}}><a href="https://www.airbnb.be/rooms/24957461?source_impression_id=p3_1563811401_qo%2BiMAo6ln2w20xO&fbclid=IwAR0AqVMC2B284JU-n6tCb-GqLjoSydE6awgShUhz35N7g4UwyAlmqRWgnvo">Gemoedelijke Bioboerderij</a></figcaption>
        </figure> 
        </td>
      </tr>
      <tr>
        <td align="left" colspan="2">Verlengd weekend met de vriendinnen. Genieten tussen fauna en flora in deze levendige bioboerderij te midden natuurschoon.</td>
      </tr>
      <tr>
          <td><b>Locatie:</b></td> <td><a href="https://www.google.com/maps/place/Heusden+Gem+Asten,+Nederland/@51.3653275,5.7280253,12z/data=!3m1!4b1!4m5!3m4!1s0x47c6de4d0f6839a7:0x252e3f5074bcff00!8m2!3d51.3614345!4d5.7943272">Heusden Gem Asten</a></td>
      </tr>
      <tr>
          <td><b>Goedgekeurd door:</b></td> <td>Kaat & Co</td>
      </tr>
      <tr>
          <td><b>Bezocht in:</b></td> <td>2019</td>
      </tr>
    </table>
      </div>
      <div className={pageLayout.side}>
          <h2>Laat je horen!</h2>
          <p> Zelf ook op een top locatie geweest? Geef het dan zeker door via <Link to="../contact_geg">onze contact pagina</Link>. </p><p> Vul zeker in wanneer je er geweest bent en de link naar de webpagina van de accomodatie.</p>
      </div>
      </div>
      </div>
  )
}